<template>
  <v-text-field
    label="Seeding Map"
    v-model="selector.map"
    :disabled="disabled"
    :hint="`Enter a seeding map ie: poolNumber, poolFinish | poolNumber, poolFinish`"
    persistent-hint
    color="color3"
    @focus="showErrors = false"
    @blur="showErrors = true"
  >
    <template v-slot:append-outer>
      <seed-map-dialog :fromRound="fromRound" @save-click="(val) => { selector.map = val }" :map="selector.map"></seed-map-dialog>
    </template>
    <template v-slot:message v-if="showErrors && highlightErrors">
      <span v-html="highlightErrors"></span>
    </template>
  </v-text-field>
</template>

<script>
import SeedMapDialog from '@/components/Tournament/SeedMap/Dialog'

export default {
  props: ['selector', 'disabled', 'fromRound'],
  data () {
    return {
      showErrors: true
    }
  },
  computed: {
    validStates () {
      const map = this.selector.map && this.selector.map.split('|')
      return map ? map.map(m => {
        const splt = m.split(',').map(m => +m)
        let v = false
        if (splt.length === 2 && splt[1]) {
          const pool = this.fromRound.pools.find(f => f.number === splt[0])
          v = !!(pool && pool.teams.length >= splt[1])
        }
        return { text: m, valid: v }
      }) : []
    },
    highlightErrors () {
      return this.invalid && 'Errors highlighted in red: ' + this.validStates.map((m, i) => {
        return m.valid ? `<span class="success--text">${i > 0 ? '|' : ''}${m.text}</span>` : `<b class="error--text">${i > 0 ? '|' : ''}${m.text}</b>`
      }).join('')
    },
    invalid () {
      return !!(this.validStates && this.validStates.find(f => !f.valid))
    }
  },
  watch: {
    invalid: function (val) {
      this.$emit('invalid-change', val)
    }
  },
  components: {
    SeedMapDialog
  },
  mounted () {
    this.$emit('invalid-change', this.invalid)
  }
}
</script>
