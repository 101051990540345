<template>
  <div :style="style" class="color3--text pl-1 pr-5">
    &nbsp;{{text}}
    <div :style="conStyle" class="connector">
      <i class="fas fa-caret-right color3--text arrow" style="left:100%;top:-7px"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: ['container', 'match', 'text', 'in', 'textStyle', 'nudgeX', 'nudgeY', 'trigger'],
  data () {
    return {
      containEl: null,
      matchEl: null
    }
  },
  computed: {
    isReady () {
      return this.containEl && this.matchEl
    },
    style () {
      return this.position && `white-space: nowrap; position: absolute; top: ${this.position.top}px; left: ${this.position.left}px;}`
    },
    conStyle () {
      return this.position && `position: absolute; width: ${(this.nudgeX || 0) - this.size}px; border-width: ${this.size}px 0 0 0; left: ${-this.nudgeX}px; top: calc(50% - ${this.size}px;`
    },
    position () {
      return this.matchEl && {
        left: this.matchEl.right + (this.nudgeX || 0) + this.size,
        top: this.matchEl.top + (this.matchEl.h / 2) + (this.nudgeY || 0) - 11.5
      }
    },
    myHeight () {
      return this.$refs && this.$refs.me && this.$refs.me.offsetHeight
    },
    size () {
      return this.lineSize || 2
    }
  },
  methods: {
    connect () {
      const p = this.container && document.getElementById(this.container)
      if (p) {
        this.containEl = this.getProps(p)
        this.containEl.el = p
      }
      const s = this.match && p.querySelector(this.match)
      if (s) {
        this.matchEl = this.getProps(s)
        this.matchEl.el = s
      }
    },
    getProps (el) {
      return {
        left: el.offsetLeft,
        top: el.offsetTop,
        h: el.offsetHeight,
        w: el.offsetWidth,
        bottom: el.offsetTop + el.offsetHeight,
        right: el.offsetLeft + el.offsetWidth,
        topAdj (a) {
          return a ? (typeof a.y === 'string' && a.y.includes('%')) ? this.h * (parseInt(a.y) / 100) : parseInt(a.y) : 0
        },
        leftAdj (a) {
          return a ? (typeof a.x === 'string' && a.x.includes('%')) ? this.w * (parseInt(a.x) / 100) : parseInt(a.x) : 0
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(this.connect, 333)
    })
  },
  watch: {
    trigger: 'connect'
  }
}
</script>

<style scoped>
.connector {
  position: absolute;
  border-style: solid;
  border-color: var(--v-color3-base);
}
.arrow {
  position: absolute
}
</style>
