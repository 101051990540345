<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    scrollable
  >
    <template v-slot:activator="{ on }">
      <v-btn color="color3--text" icon v-on="on">
        <v-icon>fas fa-info-circle</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Pool Results Seeding Styles</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row dense v-for="style in seedingStyles" :key="style.style">
            <v-col cols="12">
              <div class="title">{{style.display}}</div>
              <p v-for="(desc, i) in style.description" :key="i">{{desc}}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row dense justify="center">
            <v-btn color="color3 color3Text--text" @click.stop="dialog = false">Close</v-btn>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  props: ['seedingStyles'],
  data () {
    return {
      dialog: false
    }
  }
}
</script>

<style>

</style>
