<template>
  <v-dialog
    v-model="open"
    scrollable fullscreen
    persistent
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn color="color3 color3Text--text" fab small v-on="on" :disabled="!enabled">
        <v-icon>fas fa-hat-wizard</v-icon>
      </v-btn>
    </template>
    <v-card>
      <div>
        <v-toolbar color="color2 color2Text--text">
          <v-toolbar-title>Bracket Maker</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn color="color1 color1Text--text" fab small @click.stop="open = false">
            <v-icon>fas fa-times</v-icon>
          </v-btn>
        </v-toolbar>
      </div>
      <v-card-text v-if="open" height="100%" color="grey lighten-3" class="pt-0">
        <v-container fluid class="pa-0" id="big_container">
          <v-row dense>
            <v-col cols="12" class="caption pa-0" style="min-height: 18px">
              {{output}}
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="6">
              <v-autocomplete
                label="Number of Teams"
                v-model="teams"
                :items="Array.from({ length: maxTeams }, (v, i) => i + 1)"
                hide-details
                color="color3"
                item-color="color3"
                :disabled="!maxTeams"
              ></v-autocomplete>
            </v-col>
            <v-col>
              <v-fab-transition>
                <v-btn
                  color="white red--text"
                  small
                  v-if="seeding.filter(f => !!f).length"
                  @click.stop="clearMe"
                >
                  clear bracket
                </v-btn>
              </v-fab-transition>

            </v-col>
            <v-col cols="6" class="caption pa-0" style="min-height: 18px" v-if="false">
              <v-switch label="Show Team Names" v-model="showTeams"></v-switch>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-card height="100%" color="grey lighten-3">
                <v-toolbar dense>
                  <v-toolbar-title>Possible Finishes</v-toolbar-title>
                </v-toolbar>
                <v-container fluid>
                  <v-row dense>
                    <v-col cols="12" v-for="(p, i) in poolResults" :key="i"  >
                      <v-card>
                        <v-card-title>
                          Pool {{p.name}}
                        </v-card-title>
                        <v-list dense class="pool">
                          <template v-for="(team, i) in p.teams">
                            <v-divider v-if="i > 0" :key="`${i}-d`"></v-divider>
                            <v-list-item :key="`${i}-c`" class="team" :data-team="team.value">
                              <v-list-item-action class="sortHandle">
                                <v-icon small>fas fa-bars</v-icon>
                              </v-list-item-action>
                              <v-list-item-content>
                                <v-list-item-title>{{team.finish | ordinal}} Place</v-list-item-title>
                                <v-list-item-subtitle>{{team.name}}</v-list-item-subtitle>
                              </v-list-item-content>
                              <v-fab-transition>
                                <v-list-item-action>
                                  <v-btn
                                    :key="output"
                                    icon small
                                    @click.stop="removeMe(team)"
                                    v-if="selectedTeams.includes(team.value)"
                                  >
                                    <v-icon color="error lighten-3">fas fa-trash</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-fab-transition>
                              <v-list-item-action>
                                <v-fab-transition>
                                  <v-btn
                                    :key="output"
                                    icon small
                                    @click.stop="addTeam(team)"
                                    v-if="!selectedTeams.includes(team.value)"
                                  >
                                    <v-icon color="grey lighten-1">fas fa-plus</v-icon>
                                  </v-btn>
                                  <v-chip v-else>
                                    {{getSeed(team.value)}}
                                  </v-chip>
                                </v-fab-transition>
                              </v-list-item-action>
                            </v-list-item>
                          </template>
                        </v-list>
                        <v-container fluid class="py-0" v-if="false">
                          <v-row dense>
                            <v-col cols="12" v-for="team in p.teams" :key="team.value">
                              <v-tooltip right>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    color="color3 color3Text--text"
                                    block
                                    rounded
                                    @click.stop="addTeam(team)"
                                    :disabled="selectedTeams.includes(team.value)"
                                  >
                                    {{team.finish | ordinal}} Place
                                    <v-spacer></v-spacer>
                                    <v-icon right>fas fa-plus</v-icon>
                                  </v-btn>
                                </template>
                                <span>Click to add</span>
                              </v-tooltip>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols="4" md="2" v-if="false">
              <v-card height="100%">
                <v-toolbar dense>
                  <v-toolbar-title>Seeding</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-fab-transition>
                    <v-btn
                      color="white red--text"
                      fab small
                      v-if="seeding.length"
                      @click.stop="clearMe"
                    >
                      <v-icon>fas fa-ban</v-icon>
                    </v-btn>
                  </v-fab-transition>
                </v-toolbar>
                <v-container fluid>
                  <v-row dense>
                    <v-col class="text-center" cols="12" v-for="(team, i) in seeding" :key="team.value"  >
                      {{i + 1}} - {{team.text}}
                      <v-btn icon text small color="red" @click.stop="removeTeam(i)">
                        <v-icon small>fas fa-trash</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col md="8">
              <bracket-display v-if="bracket" :bracket="bracket" :demo="true" :key="`${keyCount}`" :noFabs="true"></bracket-display>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-fab-transition>
        <v-btn
          color="color3 color3Text--text"
          fixed bottom right fab
          @click.stop="save"
        >
          <v-icon>fas fa-save</v-icon>
        </v-btn>
      </v-fab-transition>
    </v-card>
    <table id="append">
      <tbody></tbody>
    </table>
  </v-dialog>
</template>

<script>
import { ordinal } from '@/Filters'
import BracketDisplay from '@/components/Tournament/Brackets/BracketDisplay.vue'
import Bracket from '@/classes/Bracket.js'
import { firstBy } from 'thenby'
import { mapGetters } from 'vuex'
import { Draggable } from '@shopify/draggable'

export default {
  props: ['fromRound', 'map'],
  data () {
    return {
      open: false,
      seeding: [],
      keyCount: 0,
      bracket: null,
      teams: 0,
      sortable: null
    }
  },
  computed: {
    ...mapGetters(['getTeam']),
    output () {
      return this.seeding.length > 1 && this.keyCount > -1 && this.seeding.map(m => m ? m.value : null).join('|')
    },
    selectedTeams () {
      return this.seeding.length > 1 && this.keyCount > -1 ? this.seeding.map(s => s ? s.value : null) : []
    },
    availableTeams () {
      return this.poolResults && this.poolResults.filter(t => {
        var x = this.seeding.find(f => f && f.value === t.value)
        return !x
      })
    },
    allTeams () {
      const a = []
      this.poolResults && this.poolResults.forEach(t => {
        a.push(...t.teams)
      })
      return a
    },
    maxTeams () {
      let n = 0
      if (this.poolResults) {
        this.poolResults.forEach(f => { n += f.teams.length })
      }
      return n
    },
    enabled () {
      const fr = this.fromRound
      return fr && fr.poolPlay && fr.flight && fr.flight.pools.length > 0
    },
    poolResults () {
      const fr = this.fromRound
      return fr && fr.poolPlay && fr.flight && fr.flight.pools.map(p => {
        return {
          name: p.name,
          teams: p.teams.map(t => {
            const tt = this.getTeam(t.teamId)
            const test = tt.players.length <= 2 && tt.players.length > 0
            const ttName = test ? tt.players.map(m => m.lastName).join('/') : tt.name
            return {
              text: `${ordinal(t.slot)} Place Pool ${p.name}`,
              finish: p.locked && t.summary ? t.summary.finish : t.slot,
              pool: p.number,
              get value () {
                return `${this.pool},${this.finish}`
              },
              name: p.locked ? ttName : 'Undecided'
            }
          }).sort(firstBy('finish'))
        }
      })
    },
    bracketSize () {
      return Math.max(this.seeding.length, this.teams)
    }
  },
  methods: {
    initMap () {
      if (this.map) {
        this.clearMe()
        const teams = this.map.split('|')
        this.teams = teams.length
        var i = 0
        for (const t of teams) {
          i++
          if (!t) continue
          const a = t.split(',')
          const finish = a[1]
          const p = this.fromRound.flight.pools.find(f => f.number === +a[0])
          const team = p.locked ? p.teams.find(f => f.summary && f.summary.finish) : false
          const tt = team && this.getTeam(team.teamId)
          const test = tt && tt.players.length <= 2 && tt.players.length > 0
          const ttName = tt ? test ? tt.players.map(m => m.lastName).join('/') : tt.name : 'Undecided'
          const newTeam = {
            text: `${ordinal(finish)} Place Pool ${p.name}`,
            finish: finish,
            pool: p.number,
            get value () {
              return `${this.pool},${this.finish}`
            },
            name: ttName
          }
          this.addTeam(newTeam, i - 1)
        }
      }
    },
    save () {
      this.$emit('save-click', this.output)
      this.open = false
      this.clearMe()
    },
    clearMe () {
      this.seeding = []
      this.fillBracket()
    },
    addTeam (team, i) {
      let x = this.seeding.findIndex(f => f && f.value === team.value)
      while (x > -1) {
        this.removeTeam(x)
        x = this.seeding.findIndex(f => f && f.value === team.value)
      }

      if (!i && i !== 0) {
        i = this.seeding.findIndex(f => !f)
      }
      if (i === -1) {
        this.seeding.push(team)
      } else {
        this.seeding[i] = team
      }
      this.fillBracket()
    },
    removeMe (team) {
      const x = this.seeding.findIndex(f => f && f.value === team.value)
      this.removeTeam(x)
    },
    removeTeam (i) {
      if (i > -1) {
        this.seeding[i] = null
      }
      this.fillBracket()
    },
    getBracket () {
      if (this.bracketSize <= 1) return
      this.loading = true
      const url = `/brackets/Sample?type=SINGLE_ELIM&teamCount=${this.bracketSize}`
      this.$http.get(url)
        .then(r => {
          this.bracket = new Bracket(this.$http, r.data)
          this.fillBracket()
        })
        .finally(() => {
          this.loading = false
        })
    },
    adjustSeeding () {
      while (this.seeding.length < (this.bracketSize)) {
        this.seeding.push(false)
      }
      while (this.seeding.length > (this.bracketSize)) {
        this.seeding.pop()
      }
    },
    fillBracket () {
      if (this.bracket) {
        this.adjustSeeding()
        this.bracket.matches.forEach(m => {
          const a = m.awaySeed || +m.awayMap
          const h = m.homeSeed || +m.homeMap
          if (a && a <= this.seeding.length && this.seeding[a - 1]) {
            const x = this.seeding[a - 1]
            if (x) {
              m.awayMap = x.text
              const name = x.name !== 'Undecided' ? x.name : x.text
              m.awayTeam = { name: name, showName: true }
            }
          } else if (m.awayTeam) {
            m.awayMap = m.awaySeed ? `${m.awaySeed}` : 'Bye'
            m.awayTeam = null
          }
          if (h && h <= this.seeding.length && this.seeding[h - 1]) {
            const y = this.seeding[h - 1]
            if (y) {
              m.homeMap = y.text
              const name = y.name !== 'Undecided' ? y.name : y.text
              m.homeTeam = { name: name, showName: true }
            }
          } else if (m.homeTeam) {
            m.homeMap = m.homeSeed ? `${m.homeSeed}` : 'Bye'
            m.homeTeam = null
          }
        })
        this.keyCount++
        this.$nextTick(() => {
          this.makeSortable()
        })
      }
    },
    getSeed (v) {
      return this.seeding.findIndex(f => f && f.value === v) + 1
    },
    makeSortable () {
      console.log('here')
      const pools = document.querySelectorAll('.pool')
      if (pools.length === 0) {
        console.log('no pools')
        return
      }
      const matches = document.querySelectorAll('.match')
      if (matches.length === 0) {
        console.log('no matches')
        return
      }
      if (this.sortable) {
        this.sortable.destroy()
      }
      const sortable = new Draggable(document.getElementById('big_container'), {
        draggable: '.team',
        dropzone: '.bracket-team',
        handle: '.sortHandle',
        mirror: {
          constrainDimensions: true
        }
      })

      sortable.on('drag:stop', (e) => {
        console.log('here')
        const el = document.querySelector('.draggable--over')
        if (el) {
          const homeaway = el.parentElement.className.includes('away') ? 'a' : 'h'
          const match = el.parentElement.parentElement.__vue__.$parent.matchIn
          const tv = e.originalSource.dataset.team

          if (match && tv) {
            this.addToMatch(match, tv, homeaway)
          }
          this.$nextTick(() => {
            this.makeSortable()
          })
        }
      })
      this.sortable = sortable
    },
    addToMatch (match, tv, homeaway) {
      console.log(match)
      console.log(tv)
      const seed = homeaway === 'a' ? match.awaySeed : match.homeSeed
      if (!seed) return
      const team = this.allTeams.find(f => f && f.value === tv)
      if (team) {
        this.addTeam(team, seed - 1)
      }
    }
  },
  watch: {
    'seeding.length': 'fillBracket',
    bracketSize: 'getBracket',

    open: function (v) {
      v && this.initMap()
    }
  },
  components: {
    BracketDisplay
  }
}
</script>

<style scoped>
.draggable-mirror {
  background-color: #fff;
  border: 1px solid var(--v-color2-base);
  z-index: 999;
}

</style>
