<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on: d }">
      <v-tooltip left v-model="tooltip" color="red">
        <span>Team Search <v-icon color="white">fas fa-long-arrow-right</v-icon></span>
        <template v-slot:activator="{ on: tt }">
          <v-btn
            color="color3 color3Text--text"
            fab bottom right fixed
            v-on="{ ...tt, ...d }"
          >
            <v-icon>fas fa-search</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        Team Search
      </v-toolbar>
      <v-card-text class="py-9">
        <v-autocomplete
          :key="aKey"
          v-model="selectedTeam"
          :items="teams"
          item-value="id"
          item-text="text"
          close-on-click
          dense placeholder="Select A Team"
        >
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
              <v-list-item-subtitle v-text="item.players"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ['bracket'],
  data () {
    return {
      dialog: false,
      search: null,
      selectedTeam: null,
      aKey: 0,
      tooltip: false
    }
  },
  computed: {
    ...mapGetters(['getTeam']),
    teams () {
      return this.bracket.teams.map(t => {
        const team = this.getTeam(t.teamId)
        return {
          id: t.teamId,
          name: team.name,
          players: team.playerNamesStr,
          text: `${team.name} - ${team.playerNamesStr}`
        }
      })
    }
  },
  methods: {
    highlight (matches) {
      this.$emit('highlight', matches)
      this.dialog = false
    },
    onSelect () {
      const m = this.bracket.matches.filter(m => m.activeTeamIds.includes(this.selectedTeam))
      if (m) {
        this.highlight(m.map(a => a.number))
      }
      this.selectedTeam = null
      this.search = ''
      this.aKey++
      this.dialog = false
    },
    hideTT () {
      this.tooltip = false
    },
    showTT () {
      this.tooltip = true
    }
  },
  watch: {
    selectedTeam: function (v) {
      if (v) {
        this.onSelect()
      }
    }
  },
  mounted () {
    setTimeout(this.showTT, 333)
    setTimeout(this.hideTT, 5000)
  }
}
</script>
