<template>
  <div :style="myStyle" class="connector" :class="{'lowlight': lowlight}" :key="myStyle" v-if="isReady" ref="me">
    <i class="fas fa-caret-up" :style="iconStyle" :class="{'fa-rotate-180': startIsAbove, 'color3--text': !lowlight, 'grey--text': lowlight}"></i>
  </div>
</template>

<script>
export default {
  props: ['startAnchor', 'endAnchor', 'lineSize', 'container', 'lineStyle', 'trigger', 'lowlight'],
  data () {
    return {
      startEl: null,
      endEl: null,
      containEl: null
    }
  },
  computed: {
    myStyle () {
      return this.myEl ? `top: ${this.myEl.top}px; left: ${this.myEl.startX}px; height: ${this.myEl.h}px; width: ${this.myEl.w}px; border-width: ${this.myEl.border}; border-style: ${this.lineStyle || 'solid'};` : false
    },
    iconStyle () {
      return !this.startIsAbove ? 'position: absolute; top: -5px; left: calc(100% - 3.5px);' : 'position: absolute; left: calc(100% - 3.5px);top: calc(100% - 8px)'
    },
    size () {
      return this.lineSize || 2
    },
    isReady () {
      return !!(this.startEl && this.endEl && this.containEl)
    },
    myEl () {
      if (!this.isReady) return null
      const bw = this.size
      const onTop = this.startIsAbove
      const result = {
        startX: this.startEl.left + this.startEl.leftAdj(this.startAnchor),
        endX: this.endEl.left + this.endEl.leftAdj(this.endAnchor),
        startY: this.startEl.top + this.startEl.topAdj(this.startAnchor),
        endY: this.endEl.top + this.endEl.topAdj(this.endAnchor),
        _top: onTop ? this.startEl.top + this.startEl.topAdj(this.startAnchor)
          : this.endEl.top + this.endEl.h - this.endEl.topAdj(this.endAnchor),
        get top () {
          return this._top - bw
        },
        bottom: onTop ? this.endEl.top + this.endEl.topAdj(this.endAnchor)
          : this.startEl.top + this.startEl.h - this.startEl.topAdj(this.startAnchor),
        get h () {
          return this.bottom - this.top - (onTop ? bw : 0)
        },
        get w () {
          return this.endX > this.startX ? this.endX - this.startX : this.startX - this.endX
        },
        get border () {
          const top = this.startY <= this.endY ? `${bw}px` : '0'
          const bottom = this.startY <= this.endY ? '0' : `${bw}px`
          return `${top} ${bw}px ${bottom} 0`
        }
      }
      return result
    },
    startIsAbove () {
      return this.isReady && this.startEl.top < this.endEl.top
    },
    startIsLeft () {
      return this.isReady && this.startEl.left < this.endEl.left
    },
    myself () {
      return this.$refs && this.$refs.me
    }
  },
  methods: {
    connect () {
      const p = this.container && document.getElementById(this.container)
      if (p) {
        this.containEl = this.getProps(p)
        this.containEl.el = p
      }
      const s = this.startAnchor && p.querySelector(`#${this.startAnchor.id}`)
      if (s) {
        this.startEl = this.getProps(s)
        this.startEl.el = s
      }
      const e = this.startAnchor && p.querySelector(`#${this.endAnchor.id}`)
      if (e) {
        this.endEl = this.getProps(e)
        this.endEl.el = e
      }
    },
    getProps (el) {
      return {
        left: el.offsetLeft,
        top: el.offsetTop,
        h: el.offsetHeight,
        w: el.offsetWidth,
        bottom: el.offsetTop + el.offsetHeight,
        right: el.offsetLeft + el.offsetWidth,
        topAdj (a) {
          return a ? (typeof a.y === 'string' && a.y.includes('%')) ? this.h * (parseInt(a.y) / 100) : parseInt(a.y) : 0
        },
        leftAdj (a) {
          return a ? (typeof a.x === 'string' && a.x.includes('%')) ? this.w * (parseInt(a.x) / 100) : parseInt(a.x) : 0
        }
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(this.connect, 333)
    })
  },
  watch: {
    trigger: 'connect'
  }
}
</script>

<style>
.connector {
  position: absolute;
  border-color: var(--v-color3-base);
}
.connector.lowlight {
  position: absolute;
  border-color: #9E9E9E;
}
</style>
